<template>
  <b-card class="card-congratulation-medal">

    <b-row>

      <!-- User Info: Left col -->
      <b-col
        cols="21"
        xl="6"
        class="d-flex justify-content-between flex-column"
      >
        <!-- User Avatar & Action Buttons -->
        <div class="d-flex justify-content-start">
          <b-avatar
            :src="(userData!==undefined || userData!==null) ? userData.providerData.pictureUrl : null"
            :text="avatarText(userData.providerData.name_provider)"
            :variant="`light-${resolveUserRoleVariant(userData.role)}`"
            size="104px"
            rounded
          />
          <div class="d-flex flex-column ml-1">
            <div class="mb-1">
              <h4 class="mb-0">
                {{ userData.documents.name_provider }}
              </h4>
              <span class="card-text"> Id Partners: {{ userData.documents.id_partners }}
                <b-button
                  v-clipboard:copy="userData.documents.id_partners"
                  v-clipboard:success="onCopy"
                  v-clipboard:error="onError"
                  class="btn-icon ml-2"
                  variant="primary"
                  size="sm"
                >
                  <feather-icon icon="ClipboardIcon" />
                </b-button>

              </span>

              <span class="card-text">{{ userData.documents.email_provider }}
                <b-button
                  v-clipboard:copy="userData.documents.email_provider"
                  v-clipboard:success="onCopy"
                  v-clipboard:error="onError"
                  class="btn-icon ml-2"
                  variant="primary"
                  size="sm"
                >
                  <feather-icon icon="ClipboardIcon" />
                </b-button>
                <!-- <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  class="mt-1"
                  variant="danger"

                  @click="toggleUserStatus(1,2)"
                >
                  Cambiar Status2
                </b-button> -->
              </span>

            </div>
            <div>
              <b-badge
                variant="warning"
              >
                <feather-icon
                  icon="StarIcon"
                  class="mr-25"
                />
                {{ userData.status_provider==1?"En Revisión" : "Creado en Fleet Manager" }}
              </b-badge>

            </div>
            <!-- <div class="d-flex flex-wrap">
              <b-button
                :to="{ name: 'apps-users-edit', params: { id: userData._id } }"
                variant="primary"
              >
                Edits
              </b-button>
              <b-button
                variant="outline-danger"
                class="ml-1"
              >
                Deletes
              </b-button>
            </div> -->
          </div>
        </div>

        <!-- User Stats -->

      </b-col>

      <!-- Right Col: Table -->

    </b-row>
    <b-img
      :src="require('@/assets/images/illustration/marketing.svg')"
      class="congratulation-medal"
      alt="Medal Pic"
    />
  </b-card>
</template>

<script>
import {
  BCard, BAvatar, BRow, BCol, BButton, BImg, BBadge,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useUsersList from '../users-list/useUsersList'

export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    BCard, BRow, BCol, BAvatar, BButton, BImg, BBadge,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },

  },
  data() {
    return {}
  },
  mounted() {

  },
  methods: {
    changeProspectStatus() {
      // console.log(this.status, ':', this.$route.params.id)

      // store.dispatch('app-user/changeProspectStatus', { status: this.status, id: this.$route.params.id })
      //   .then(response => {
      //     console.log(response)
      //   })
      //   .catch(error => {
      //     if (error.response.status === 404) {
      //       console.log(error)
      //     }
      //   })
    },
    onCopy() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Texto copiado',
          icon: 'BellIcon',
        },
      })
    },
    onError() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Fallo al copiar texto',
          icon: 'BellIcon',
        },
      })
    },
  },
  setup() {
    const { resolveUserRoleVariant } = useUsersList()
    return {
      avatarText,
      resolveUserRoleVariant,
    }
  },
}
</script>

<style>

</style>
